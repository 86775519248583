<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="800px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="名称" prop="typeName">
        <el-input v-model="form.typeName" style="width: 400px;"/>
      </el-form-item>
      <el-form-item label="英文名称" prop="typeNameEn">
        <el-input v-model="form.typeNameEn" style="width: 400px;"/>
      </el-form-item>
      <el-form-item label="上级内容">
        <treeselect v-model="form.upId" :options="srCmsContentTypes" style="width: 200px;" placeholder="选择上级栏目" />
      </el-form-item>
      <el-form-item label="图标上传">
        <el-upload
          class="upload"
          :action="uploadUrl"
          :on-success="handleSuccess"
          :show-file-list="false"
          >
          <el-button size="small" type="primary">上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传一个jpg/png图片</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <img class="icon-img" v-if="form.icon" :src="form.icon" alt="">
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input type="textarea" autosize v-model="form.description" style="width: 600px;"/>
      </el-form-item>
      <el-form-item label="描述英文" prop="descriptionEn">
        <el-input type="textarea" autosize v-model="form.descriptionEn" style="width: 600px;"/>
      </el-form-item>
      <el-form-item label="是否有效" prop="status">
        <el-radio v-model="form.status" label=1>是</el-radio>
        <el-radio v-model="form.status" label=0>否</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/cms/srCmsContentType'
import Treeselect from '@riophae/vue-treeselect'
import IconSelect from '@/components/IconSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect, IconSelect },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      form: {
        id: '',
        upId: '0',
        typeName: '',
        icon:'',
        description:'',
        descriptionEn:'',
        status: '1'
      },
      rules: {
        typeName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        typeNameEn: [
          { required: true, message: '请输入英文名称', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        upId: '0',
        typeName: '',
        icon:'',
        description:'',
        descriptionEn:'',
        status: '1'
      }
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.icon=response.data.content[0].url;
    },
  }
}
</script>

<style scoped>
.icon-img {
  width: 50px;
  height: 50px;
}
</style>
