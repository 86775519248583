<template>
  <div class="app-container">
    <eHeader :query="query" :srCmsContentTypes="srCmsContentTypes"/>
    <!--表格渲染-->
    <!-- <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;"> -->
    <tree-table v-loading="loading" :data="data" :expand-all="true" :columns="columns" stripe size="small">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="typeName" label="名称"/>
      <el-table-column prop="typeNameEn" label="英文名称"/>
      <el-table-column label="状态" width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.status==1?'正常':'锁定'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <edit
            v-if="checkPermission(['ADMIN','SRCMSCONTENTTYPE_ALL','SRCMSCONTENTTYPE_EDIT'])"
            :data="scope.row"
            :sup_this="sup_this"
            :srCmsContentTypes="srCmsContentTypes"
          />
          <el-popover
            v-if="checkPermission(['ADMIN','SRCMSCONTENTTYPE_ALL','SRCMSCONTENTTYPE_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
              >确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    <!-- </el-table> -->
        </tree-table>
    <!--分页组件-->
    <!-- <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    /> -->
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import treeTable from '@/components/TreeTable'
import { parseTime } from '@/utils/index'
import initData from "@/mixins/initData";
import { del, getSrCmsContentTypeTree } from "@/api/cms/srCmsContentType";
import eHeader from "@/components/cms/contenttype/header";
import edit from "@/components/cms/contenttype/edit";
export default {
  name:'contenttype',
  components: { eHeader, edit ,treeTable},
  mixins: [initData],
  data() {
    return {
      columns: [
        {
          text: '名称',
          value: 'typeName'
        }
      ],
      delLoading: false,
      sup_this: this,
      srCmsContentTypes: []
    };
  },
  created() {
    this.getSrCmsContentTypes();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "cms/crm/srCmsContentType";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const typeName = query.typeName;
      if (typeName!==null && typeName!=="") {
        this.params["typeName"] = typeName;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    getSrCmsContentTypes() {
      getSrCmsContentTypeTree().then(res => {
        this.srCmsContentTypes = [];
        const srCmsContentType = { id: 0, label: "root-根", children: [] };
        srCmsContentType.children = res;
        this.srCmsContentTypes.push(srCmsContentType);
      });
    }
  }
};
</script>

<style scoped>
</style>
